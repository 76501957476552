import React from 'react';

const SectionTab = ({title, img, children}) => {

    return (
        <section className="osiris-plateform-tab-section">

            <div className="center">
                <div className="wrapper-demi">
                    <div className="left">
                        <h2>
                            {title}
                        </h2>
                        {children}
                    </div>

                    <div className="right">
                        <img src={img} alt="PDG"/>
                    </div>
                </div>

            </div>
        </section>
    )
};

export default SectionTab;