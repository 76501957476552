import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from "@mui/material";
import CustomTabPanel from "../../../../components/CustomTabPanel";
import BtnDocumentDownload from "../../../../components/ButtonDocumentDownload";

export default function OsirisTab1() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }}
             className="tabs-container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}
                 className="tabs-group">
                <Tabs value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example">
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-cloud-arrow-up"></i>
                            <span>Web Platform</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-database"></i>
                            <span>Data Management</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-umbrella"></i>
                            <span>Insurance</span>
                        </div>
                    }/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <p className="p20-light">
                    The solution is deployed on
                    a<strong> cloud </strong>that meets
                    the client's needs. This dedicated space
                    allows for connecting new
                    services or managing in real-time
                    the necessary computing resources.
                </p>

                <p className="p20-light">
                    The application is<strong> easily accessible </strong>
                    by the user community
                    to optimize the<strong> sharing </strong>of
                    information.
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <p className="p20-light">
                    Our combination of RPA solutions
                    offers a tool capable of both
                    <strong> managing data and
                        leveraging </strong>its potential.
                </p>

                <p className="p20-light">
                    These solutions are primarily
                    based on<strong> data science </strong>techniques
                    and developed with R or Python languages.
                </p>

            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <p className="p20-light">
                    The solution is specifically designed
                    to meet the needs of
                    <strong> insurers </strong>and
                    <strong> reinsurers</strong>.
                </p>

                <p className="p20-light">
                    It fully integrates into
                    the<strong> processes </strong>of the industry
                    and ensures the required level of
                    <strong> security </strong>to protect data and calculations.
                </p>
            </CustomTabPanel>

        </Box>

    );
}
