import React from 'react';
import CardWithBorder from "../../components/card/CardWithBorder";
import DivLanguage from "../../components/DivLanguage";

const SectionWawExpertise = (props) => {
    return (
        <section className="waw-expertise">
            <div className="center">
                <DivLanguage language="fr" className="container">
                    <h2>
                        Notre équipe = Actuariat x Technologie
                    </h2>
                    <p className="p20-regular">
                        Notre équipe est composée de profils variés nous
                        permettant de remplir notre objectif: créer des
                        applications performantes pour les métiers de l'actuariat.
                    </p>
                    <p className="p20-regular">
                        Ainsi, nous sommes fiers de compter dans notre équipe des
                        personnes passionnées par les technologies de
                        la donnée, ou encore par la modélisation et des techniques de l'assurance.
                    </p>
                </DivLanguage>
                <DivLanguage language="en" className="container">
                    <h2>
                        Our team = Actuarial science x Technology
                    </h2>
                    <p className="p20-regular">
                        Our team is composed of diverse profiles that enable
                        us to achieve our goal: to create high-performance applications for
                        actuarial professions.
                    </p>
                    <p className="p20-regular">

                        We are proud to have people in our team who are passionate
                        about data technologies, as well as modeling and insurance techniques.
                    </p>
                </DivLanguage>

                <DivLanguage language="fr" className="wrapper-demi">
                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-umbrella"
                                    title="Actuariat">
                        <p className="p18-regular">
                            <strong>Comptabilité </strong>
                            Multi-norme de l’Assurance
                            (IFRS, Statutaire, Solvabilité 2),
                            comptabilité technique
                        </p>
                        <p className="p18-regular">
                            <strong>Modélisations </strong>
                            des actifs et des
                            passifs : formule standard,
                            projection de bilans, générateurs
                            de scénarios économiques
                        </p>
                        <p className="p18-regular">
                            <strong>Provisionnement, </strong>
                            tarification, gestion Actif-Passif,
                            processus ORSA
                        </p>
                    </CardWithBorder>

                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-microchip"
                                    title="Technologies">
                        <p className="p18-regular">
                            <strong>Cloud Computing : </strong>
                            AWS, OVH, Azure
                        </p>
                        <p className="p18-regular">
                            <strong>Data </strong>
                            engineering / <strong>data </strong>
                            science : R, Python, SQL, Scala
                        </p>
                        <p className="p18-regular">
                            <strong>Développement web : </strong>
                            Java EE, Javascript, …
                        </p>
                    </CardWithBorder>
                </DivLanguage>

                <DivLanguage language="en" className="wrapper-demi">
                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-umbrella"
                                    title="Actuarial">
                        <p className="p18-regular">
                            <strong>Accounting </strong>
                            Multi-standard Insurance
                            (IFRS, Statutory, Solvency II),
                            technical accounting
                        </p>
                        <p className="p18-regular">
                            <strong>Modeling </strong>
                            of assets and
                            liabilities: standard formula,
                            balance sheet projection, economic
                            scenario generators
                        </p>
                        <p className="p18-regular">
                            <strong>Provisioning, </strong>
                            pricing, Asset-Liability management,
                            ORSA process
                        </p>
                    </CardWithBorder>

                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-microchip"
                                    title="Technologies">
                        <p className="p18-regular">
                            <strong>Cloud Computing: </strong>
                            AWS, OVH, Azure
                        </p>
                        <p className="p18-regular">
                            <strong>Data </strong>
                            engineering / <strong>data </strong>
                            science: R, Python, SQL, Scala
                        </p>
                        <p className="p18-regular">
                            <strong>Web development: </strong>
                            Java EE, Javascript, …
                        </p>
                    </CardWithBorder>
                </DivLanguage>

            </div>
        </section>
    )
};

export default SectionWawExpertise;