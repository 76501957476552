import React, {useState} from 'react';
import CardLink from "../../components/card/CardLink";
import DivLanguage from "../../components/DivLanguage";
import BtnDocumentDownload from "../../components/ButtonDocumentDownload";


const SectionProductsDeployment = (props) => {

    return (
        <section className="products-deploiment-section wave-section">
            <DivLanguage language="fr" className="center">
                <div className="m-15">

                    <h2>
                        Deux options de déploiement d'Osiris
                    </h2>

                    <p className="p20-medium">
                        <span className="span-blue">ALPHAWAVE</span> propose
                        deux options de déploiement pour s'adapter aux besoins
                        des assureurs.
                        <ul className="m-15">
                            <li>
                                On-premise : l’application est installée et
                                hébergée sur l’infrastructure cloud du client
                            </li>
                            <li>
                                SaaS* : l’application est installée et
                                hébergée sur l’infrastructure cloud d’Alphawave
                            </li>
                        </ul>
                    </p>

                    <p className="p20-medium">
                        *Software as a Service
                    </p>
                </div>

                <div className="line-cards wrapper-demi">

                    <CardLink title="Osiris Enterprise On premise">
                        <>

                            <p>
                                <strong>Pour qui? </strong>
                            </p>

                            <p className="p16-regular">
                                Ce déploiement convient pour les clients ayant une stratégie cloud avancée
                                et souhaitant mutualiser des coûts d'infrastructure et de gestion.
                            </p>
                            <p>
                                <strong>Quel cloud?</strong>
                            </p>

                            <p className="p16-regular">
                                Les clouds supportés sont Azure et AWS.
                            </p>
                            <p>
                                <strong>A quel coût?</strong>
                            </p>
                            <p className="p16-regular">
                                <li>
                                    Licences annuelles
                                </li>
                                <li>
                                    En option: forfait déploiement
                                </li>

                            </p>


                        </>
                    </CardLink>

                    <CardLink title="Osiris Enterprise SaaS">
                        <>
                            <p>
                                <strong>Pour qui? </strong>
                            </p>

                            <p className="p16-regular">
                                Ce déploiement convient pour les clients de toutes tailles
                                souhaitant avoir une expérience optimale d'utilisation
                                grâce à notre connaissance de l'application et de son architecture.
                            </p>
                            <p>
                                <strong>Quel cloud?</strong>
                            </p>

                            <p className="p16-regular">
                                L'application est déployée sur notre cloud
                                AWS sur un réseau dédié.
                            </p>
                            <p>
                                <strong>A quel coût?</strong>
                            </p>
                            <p className="p16-regular">
                                <li>
                                    Licences annuelles
                                </li>
                                <li>
                                    Coût d'infrastructure au forfait ou au réel
                                </li>
                            </p>

                        </>
                    </CardLink>


                </div>



            </DivLanguage>
            <DivLanguage language="en" className="center">
                <div className="m-15">

                    <h2>
                        Two deployment options for Osiris
                    </h2>

                    <p className="p20-medium">
                        <span className="span-blue">ALPHAWAVE</span> offers two deployment options
                        to meet the needs of insurers.
                        <ul className="m-15">
                            <li>
                                On-premise: the application is installed and
                                hosted on the client's cloud infrastructure.
                            </li>
                            <li>
                                SaaS*: the application is installed and
                                hosted on Alphawave's cloud infrastructure.
                            </li>
                        </ul>
                    </p>

                    <p className="p20-medium">
                        *Software as a Service
                    </p>
                </div>

                <div className="line-cards wrapper-demi">

                    <CardLink title="Osiris Enterprise On premise">
                        <>

                            <p>
                                <strong>For whom?</strong>
                            </p>

                            <p className="p16-regular">

                                This deployment is suitable for clients with an advanced cloud strategy who wish to
                                share infrastructure and management costs.
                            </p>
                            <p>
                                <strong>Which cloud?</strong>
                            </p>

                            <p className="p16-regular">
                                The supported clouds are Azure and AWS.
                            </p>
                            <p>
                                <strong>What is the cost?</strong>
                            </p>
                            <p className="p16-regular">
                                <li>
                                    Annual licenses
                                </li>
                                <li>
                                    Optional: Deployment package
                                </li>

                            </p>


                        </>
                    </CardLink>

                    <CardLink title="Osiris Enterprise SaaS">
                        <>
                            <p>
                                <strong>For whom?</strong>
                            </p>

                            <p className="p16-regular">
                                This deployment is suitable for clients of all sizes who wish to have an optimal
                                user experience thanks to our knowledge of the application and its architecture.
                            </p>
                            <p>
                                <strong>Which cloud?</strong>
                            </p>

                            <p className="p16-regular">
                                The application is deployed on our AWS cloud on a dedicated network.
                            </p>
                            <p>
                                <strong>What is the cost?</strong>
                            </p>
                            <p className="p16-regular">
                                <li>
                                    Annual licenses
                                </li>
                                <li>
                                    Infrastructure cost is either fixed or based on actual usage.
                                </li>
                            </p>

                        </>
                    </CardLink>


                </div>


            </DivLanguage>
            <BtnDocumentDownload documentPath={"/SaaS/saas-management-pdf.pdf"}/>

        </section>
    )
};

export default SectionProductsDeployment;