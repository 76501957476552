import React from 'react';
import DivLanguage from "../../components/DivLanguage";

const SectionProductsTop = (props) => {
    return (
        <section className="products-top-section wave-section">
            <DivLanguage language="fr" className="center">
                <div className="title">
                    <h1>
                        Des solutions adaptées à vos besoins
                    </h1>
                </div>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <div className="title">
                    <h1>
                        Solutions adapted to your needs
                    </h1>
                </div>
            </DivLanguage>

        </section>
    )
};

export default SectionProductsTop;