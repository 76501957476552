import React from 'react';
import "../../style/products.scss";
import SectionContact from "../../components/SectionContact";
import SectionProductsTop from "./SectionProductsTop";
import SectionProductsDeployment from "./SectionProductsDeployment";
import SectionProductsCompareOffers from "./SectionProductsCompareOffers";
import VismeNewsLetterComponent from "../../components/visme/NewsletterSubscribe";

const ProductsPage = (props) => {
    return (
        <>
            <SectionProductsTop/>
            <VismeNewsLetterComponent/>
            <SectionProductsDeployment/>

            <SectionProductsCompareOffers/>

            <SectionContact className="white-wave"/>
        </>
    )
};

export default ProductsPage;