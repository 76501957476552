import React from 'react';
import CardLink from "../../components/card/CardLink";
import BtnLinkCenter from "../../components/BtnLinkCenter";
import DivLanguage from "../../components/DivLanguage";

const SectionProductServices = () => {
    return (
        <section className="home-products-services wave-section">

            <div className="center">
                <div className="content">

                    <h2>
                        Des solutions adaptées basées sur Osiris Enterprise
                    </h2>
                    <DivLanguage language="fr" className="line-cards wrapper-demi">
                        <CardLink title="Osiris Enterprise" link="osiris">

                            <p style={{marginTop: "0.5em", marginBottom: "1em"}}>

                                Osiris est utilisé par nos clients assureurs et réassureurs
                                pour gérer leurs données <b>comptables et actuarielles.</b>

                            </p>


                        </CardLink>


                        <CardLink title="Nos solutions" link="products"
                                  className="grey-card">


                            <p style={{marginTop: "0.5em", marginBottom: "1em"}}>
                                Nous proposons trois modes de déploiement de la plateforme pour s'adapter aux besoins
                                opérationnels, à votre gouvernance et aux exigences de sécurité.
                            </p>
                        </CardLink>
                    </DivLanguage>
                    <DivLanguage language="en" className="line-cards wrapper-demi">
                        <CardLink title="Osiris Enterprise" link="osiris">
                            <p style={{marginTop: "0.5em", marginBottom: "1em"}}>
                                Osiris is used by our insurer and reinsurer clients
                                to manage their <b>accounting and actuarial</b> data.
                            </p>
                        </CardLink>

                        <CardLink title="Our Solutions" link="products"
                                  className="grey-card">
                            <p style={{marginTop: "0.5em", marginBottom: "1em"}}>
                                We offer three deployment modes of the platform to adapt to operational needs,
                                your governance, and security requirements.
                            </p>
                        </CardLink>
                    </DivLanguage>

                    <DivLanguage language="fr">
                        <BtnLinkCenter value="Plus de détails sur nos solutions"
                                       link="/products"
                                       className="big-button txt-color-dark"/>
                    </DivLanguage>
                    <DivLanguage language="en">
                        <BtnLinkCenter value="More details about our solutions"
                                       link="/products"
                                       className="big-button txt-color-dark"/>
                    </DivLanguage>


                </div>
            </div>
        </section>
    )
};

export default SectionProductServices;