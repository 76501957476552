import React from 'react';
import CardWithBorder from "../../components/card/CardWithBorder";
import CardLineChecked from "../../components/card/CardLineChecked";
import BtnLinkCenter from "../../components/BtnLinkCenter";
import DivLanguage from "../../components/DivLanguage";

const SectionProductsCompareOffers = (props) => {
    return (
        <section className="products-compare-offers-section">

            <DivLanguage language="fr" className="center">
                <div className="m-15">
                    <h2>
                        Comparer nos solutions
                    </h2>
                    <p className="p20-medium">
                        Le tableau ci-dessous indique les
                        responsabilités conservées par le
                        client pour chaque solution.
                    </p>
                </div>

                <div className="wrapper-demi">
                    <CardWithBorder title="On premise" icon="fa-solid fa-server">
                        <CardLineChecked text="Administration de l'application"
                                         isChecked={true}/>
                        <CardLineChecked text="Administration des systèmes"
                                         isChecked={true}/>
                        <CardLineChecked text="Suivi de la performance et de la disponibilité"
                                         isChecked={true}/>
                        <CardLineChecked text="Suivi des menaces"
                                         isChecked={true}/>
                        <CardLineChecked text="Support aux utilisateurs"
                                         isChecked={false}/>
                    </CardWithBorder>
                    <CardWithBorder title="SaaS" icon="fa-solid fa-cloud">
                        <CardLineChecked text="Administration de l'application"
                                         isChecked={false}/>
                        <CardLineChecked text="Administration des systèmes"
                                         isChecked={false}/>
                        <CardLineChecked text="Suivi de la performance et de la disponibilité"
                                         isChecked={false}/>
                        <CardLineChecked text="Suivi des menaces"
                                         isChecked={false}/>
                        <CardLineChecked text="Support aux utilisateurs"
                                         isChecked={false}/>

                    </CardWithBorder>

                </div>

                <BtnLinkCenter value="Contacter nous"
                               link="/contact"
                               className="txt-color-dark"/>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <div className="m-15">
                    <h2>
                        Compare our solutions
                    </h2>
                    <p className="p20-medium">
                        The table below shows the responsibilities retained by the client for each solution.
                    </p>
                </div>

                <div className="wrapper-demi">

                    <CardWithBorder title="On premise" icon="fa-solid fa-server">
                        <CardLineChecked text="Application administration"
                                         isChecked={true}/>
                        <CardLineChecked text="System Administration"
                                         isChecked={true}/>
                        <CardLineChecked text="Performance and Availability Monitoring"
                                         isChecked={true}/>
                        <CardLineChecked text="Threats Monitoring"
                                         isChecked={true}/>
                        <CardLineChecked text="User Support"
                                         isChecked={false}/>
                    </CardWithBorder>
                    <CardWithBorder title="SaaS" icon="fa-solid fa-cloud">
                        <CardLineChecked text="Application administration"
                                         isChecked={false}/>
                        <CardLineChecked text="System Administration"
                                         isChecked={false}/>
                        <CardLineChecked text="Performance and Availability Monitoring"
                                         isChecked={false}/>
                        <CardLineChecked text="Threats Monitoring"
                                         isChecked={false}/>
                        <CardLineChecked text="User Support"
                                         isChecked={false}/>

                    </CardWithBorder>


                </div>

                <BtnLinkCenter value="Contact us"
                               link="/contact"
                               className="txt-color-dark"/>
            </DivLanguage>


        </section>
    )
};

export default SectionProductsCompareOffers;