import React from 'react';
import Button from "../../components/Button";
import DivLanguage from "../../components/DivLanguage";

const SectionTop = () => {
    return (<section className="home-top-section wave-section">


        <div className="center">
            <DivLanguage language="fr" className="title">
                <h1>
                    La technologie au service de la donnée
                </h1>
                <span className="p20-regular">
                        Alphawave accompagne ses clients du secteur de l'assurance
                        dans les projets d'harmonisation des données et d'automatisation
                        des processus métiers.
                      </span>

                <Button value="En savoir plus"
                        link="/who-are-we"/>
            </DivLanguage>
            <DivLanguage language="en" className="title">
                <h1>
                    Technology at the Service of Data
                </h1>
                <span className="p20-regular">
                        Alphawave supports its clients in the insurance sector
                        in data harmonization projects and business process automation.
                    </span>

                <Button value="Learn More"
                        link="/who-are-we"/>
            </DivLanguage>
        </div>
    </section>)
};

export default SectionTop;