import React from 'react';
import "../../style/osiris.scss";
import Image016 from "../../assets/images/016.png";
import ImageReport from "../../assets/images/claims-report-site.png";
import SectionContact from "../../components/SectionContact";
import SectionOsirisConstruction from "./SectionOsirisConstruction";
import SectionOsirisSolution from "./SectionOsirisSolution";
import SectionOsirisTop from "./SectionOsirisTop";
import SectionTab from "./SectionTab";
import OsirisTab2Fr from "./tabs/fr/OsirisTab2";
import OsirisTab1Fr from "./tabs/fr/OsirisTab1";
import OsirisTab2En from "./tabs/en/OsirisTab2";
import OsirisTab1En from "./tabs/en/OsirisTab1";
import DivLanguage from "../../components/DivLanguage";
import BtnDocumentDownload from "../../components/ButtonDocumentDownload";
import VismeNewsLetterComponent from "../../components/visme/NewsletterSubscribe";

const OsirisPage = (props) => {
    return (
        <>
            <SectionOsirisTop/>

            <SectionOsirisSolution/>

            <DivLanguage language="fr">
                <SectionTab title="Qu’est-ce que la plateforme OSIRIS ?"
                            img={Image016}>
                    <OsirisTab1Fr/>
                    <BtnDocumentDownload buttonLabel={"Usage de l'API"} documentPath={"/HowTo/use-OsirisR-pdf.pdf"}/>
                </SectionTab>
            </DivLanguage>
            <DivLanguage language="en">
                <SectionTab title="What is the OSIRIS platform?"
                            img={Image016}>
                    <OsirisTab1En/>
                </SectionTab>

                <BtnDocumentDownload buttonLabel={"API Examples"} documentPath={"/HowTo/use-OsirisR-pdf.pdf"}/>
            </DivLanguage>
            <VismeNewsLetterComponent/>

            <SectionOsirisConstruction/>

            <DivLanguage language="fr">
                <SectionTab title="Pourquoi utiliser la plateforme OSIRIS ?"
                            img={ImageReport}>
                    <OsirisTab2Fr/>
                </SectionTab>
            </DivLanguage>
            <DivLanguage language="en">
                <SectionTab title="Why use the OSIRIS platform?"
                            img={ImageReport}>
                    <OsirisTab2En/>
                </SectionTab>
            </DivLanguage>


            <SectionContact className="blue-wave"/>
        </>
    )
};

export default OsirisPage;