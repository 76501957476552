import React from 'react';
import "../../style/components/card-team-profile.scss"
import Button from "../Button";

const CardTeamProfile = ({name="DUPONT", jobTitle="Actuaire", image, link, children}) => {

    return (
        <div className="team-card">
            <div className="team-profile">
                <div className="top">
                    <img src={image} alt="PDG"/>
                    <h3>{name}</h3>
                    <h4 className="span-orange">{jobTitle}</h4>
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
            <div className="bottom">
                <Button value="Profil Linkedin"
                        className="button-in-profile"
                        link={link}/>
            </div>
        </div>
    )
};

export default CardTeamProfile;