import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import "../../style/components/header.scss";
import Logo from "../../assets/logo/logo-alpha-white.png";
import DivLanguage from "../DivLanguage";

const Header = () => {

    const [click, setClick] = useState(false);
    const [header, setHeader] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    }


    const [language, setLanguage] = useState(createInitialLanguageState("fr"));

    useEffect(() => {
        console.log("Use effect set")
        window.localStorage.setItem('language', language);
    }, [language]);
    useEffect(() => {
        console.log("Use effect get")
        const data = window.localStorage.getItem('language');
        if (data !== null) setLanguage(data);
        document.documentElement.lang = language;
    }, []);


    function createInitialLanguageState(defaultLanguage) {
        const data = window.localStorage.getItem('language');
        if (data !== null){
            console.log("Get language from previous value")
            document.documentElement.lang = data;
            return (data)
        }

        if (data === null){
            document.documentElement.lang = defaultLanguage;
        }

        return defaultLanguage;
    }

    const changeLanguageBack = (language) => {
        console.log("change language")
        setLanguage(language);
        window.location.reload();

    }
    const handleChange = (event) => {
        changeLanguageBack(event.target.value);
        console.log('Selected value:', event.target.value);
    };

    window.addEventListener('scroll', changeBackground);


    return (
        <header className={header ? 'blue-header active' : 'blue-header'}>
            <DivLanguage language="fr" className="center">

                {/*LOGO*/}
                <NavLink to="/" className="logo" onClick={closeMobileMenu}>
                    <img src={Logo} alt="PDG"/>
                </NavLink>

                {/*Menu icon responsive*/}
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>

                <nav className={click ? 'nav-menu nav-active' : 'nav-menu'}>
                    <div className="nav-item">
                        <NavLink to="/"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Accueil
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/osiris"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Osiris
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/products"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Solutions
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/who-are-we"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Qui sommes nous ?
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/contact"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Contact
                        </NavLink>
                    </div>
                    <select className="select-mobile"
                            value={language}
                            onChange={handleChange}>
                        <option>fr</option>
                        <option>en</option>
                    </select>

                </nav>
                <select className="select-desktop" value={language}
                        onChange={handleChange}>
                    <option>fr</option>
                    <option>en</option>
                </select>

            </DivLanguage>
            <DivLanguage language="en" className="center">

                {/*LOGO*/}
                <NavLink to="/" className="logo" onClick={closeMobileMenu}>
                    <img src={Logo} alt="alphawave"/>
                </NavLink>

                {/*Menu icon responsive*/}
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>

                <nav className={click ? 'nav-menu nav-active' : 'nav-menu'}>
                    <div className="nav-item">
                        <NavLink to="/"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Home
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/osiris"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Osiris
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/products"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Solutions
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/who-are-we"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Who are we?
                        </NavLink>
                    </div>

                    <div className="nav-item">
                        <NavLink to="/contact"
                                 className='nav-links p18-medium'
                                 onClick={closeMobileMenu}>
                            Contact
                        </NavLink>
                    </div>
                    <select className="select-mobile"
                            value={language}
                            onChange={handleChange}>
                        <option>fr</option>
                        <option>en</option>
                    </select>

                </nav>
                <select className="select-desktop" value={language}
                        onChange={handleChange}>
                    <option>fr</option>
                    <option>en</option>
                </select>

            </DivLanguage>
        </header>
    )
};

export default Header;