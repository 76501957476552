import React from 'react';
import CardWithBorder from "../../components/card/CardWithBorder";
import BtnLinkCenter from "../../components/BtnLinkCenter";
import DivLanguage from "../../components/DivLanguage";

const SectionWhyOsiris = (props) => {
    return (
        <section className="home-why-osiris">
            <div className="center">
                <DivLanguage language="fr" className="wrapper-demi">
                    <div className="left">
                        <div className="title-n-description">
                            <h2>
                                Pourquoi utiliser l'application
                                <span className="span-blue"> OSIRIS </span>?
                            </h2>
                            <p className="p20-medium">
                                Osiris permet aux assureurs de gérer
                                leurs données actuarielles et financières.
                                Il simplifie la préparation et l’analyse
                                des données et permet d'organiser des traitements
                                complexes de manière simplifiée.
                            </p>
                        </div>
                        <CardWithBorder title="Gain de temps et fiabilité"
                                        icon="fa fa-clock-o">
                            <>
                                <p className="p18-regular">


                                    Vos collaborateurs passent moins de temps à
                                    rassembler, nettoyer vos données internes ou
                                    externes. Ils se focalisent sur le <b>contrôle et
                                    l'analyse des résultats</b>.


                                </p>

                                <p className="p18-regular">
                                    Les objets et les données d'Osiris sont <b>versionnés</b>
                                    pour la traçabilité et l'auditabilité. Vos missions
                                    les plus critiques sont sécurisées.
                                </p>
                            </>
                        </CardWithBorder>
                    </div>
                    <div className="right">
                        <CardWithBorder title="La puissance de calcul"
                                        icon="fa-solid fa-diagram-project">
                            <>
                                <p className="p18-regular">
                                    Le système d'exécution absorbe vos tâches calculatoires
                                    <b> les plus complexes </b> grâce à une distribution native
                                    des calculs.
                                    Vous pouvez vous concentrer sur <b>l'algorithme métier.</b>
                                </p>
                                <p className="p18-regular">
                                    L'architecture de l'application
                                    prévoit une mise à <b>l'échelle automatique </b>
                                    pour absorber vos charges de travail les plus critiques.
                                </p>

                            </>
                        </CardWithBorder>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="wrapper-demi">
                    <div className="left">
                        <div className="title-n-description">
                            <h2>
                                Why use the
                                <span className="span-blue"> OSIRIS </span> application?
                            </h2>
                            <p className="p20-medium">
                                Osiris allows insurers to manage
                                their actuarial and financial data.
                                It simplifies data preparation and analysis
                                and enables organizing complex processes in a simplified manner.
                            </p>
                        </div>
                        <CardWithBorder title="Time Savings and Reliability"
                                        icon="fa fa-clock-o">
                            <>
                                <p className="p18-regular">
                                    Your employees spend less time gathering and cleaning
                                    your internal or external data. They focus on
                                    <b> controlling and analyzing results</b>.
                                </p>

                                <p className="p18-regular">
                                    Osiris objects and data are <b>versioned</b>
                                    for traceability and auditability. Your most critical
                                    tasks are secured.
                                </p>
                            </>
                        </CardWithBorder>
                    </div>
                    <div className="right">
                        <CardWithBorder title="Computing Power"
                                        icon="fa-solid fa-diagram-project">
                            <>
                                <p className="p18-regular">
                                    The execution system absorbs your
                                    <b> most complex </b> computational tasks thanks to native
                                    distribution of calculations.
                                    You can focus on the <b>business algorithm.</b>
                                </p>
                                <p className="p18-regular">
                                    The application's architecture
                                    provides <b>automatic scaling </b>
                                    to handle your most critical workloads.
                                </p>
                            </>
                        </CardWithBorder>
                    </div>
                </DivLanguage>


                <DivLanguage language="fr" className="wrapper-demi">
                    <BtnLinkCenter link="/osiris"
                                   value="Plus de détails sur Osiris"
                                   className="big-button txt-color-dark"/>
                </DivLanguage>

                <DivLanguage language="en" className="wrapper-demi">
                    <BtnLinkCenter link="/osiris"
                                   value="More details about Osiris"
                                   className="big-button txt-color-dark"/>
                </DivLanguage>


            </div>
        </section>
    )
};

export default SectionWhyOsiris;