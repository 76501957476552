import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from "@mui/material";
import CustomTabPanel from "../../../../components/CustomTabPanel";

export default function OsirisTab2() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}
             className="tabs-container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}
                 className="tabs-group">
                <Tabs value={value} onChange={handleChange}
                      aria-label="basic tabs example">
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-diagram-project"></i>
                            <span>Polyvalente</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-diagram-next"></i>
                            <span>Dynamique</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-microchip"></i>
                            <span>Technologique</span>
                        </div>
                    }/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <p className="p20-light">
                    L’ensemble de la
                    <strong> chaîne de gestion de la donnée </strong>
                    peut être intégrée dans l’outil :
                    standardisation, stockage, analyse,
                    et utilisation des données.
                </p>

                <p className="p20-light">
                    La solution offre<strong> différents
                    services : </strong>contrôles des flux
                    financiers, automatisation des comptes,
                    alimentation de rapports réglementaires,
                    tarification et suivi du risque.
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <p className="p20-light">
                    Les utilisateurs travaillent
                    en<strong> interaction </strong>avec
                    le lecteur pour qualifier et
                    <strong> valider </strong>les données.
                </p>

                <p className="p20-light">
                    Ils ont aussi la<strong> maîtrise </strong>
                    de leurs fichiers dans l’explorateur et
                    peuvent utiliser ou<strong> développer </strong>
                    leurs propres  rapports dans la libraire :
                    des<strong> contrôles de cohérence </strong>
                    (données tête par tête et bordereaux),
                    des<strong> statistiques </strong>(triangles de
                    liquidation) ou des<strong> données
                    réglementaires </strong>(états ministériels)
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <p className="p20-light">
                    Le<strong> déploiement en SaaS </strong>
                    sur un cloud privé rend la gestion,
                    la maintenance et l’installation simples
                    et<strong> flexibles. </strong>Le mode SaaS
                    garantit l’évolutivité de la solution
                    et<strong> minimise les coûts.</strong>
                </p>

                <p className="p20-light">
                    Par ailleurs, l’ensemble des flux et
                    des données sont<strong> sécurisés </strong>et
                    la piste d’audit est entièrement conservée.
                </p>
            </CustomTabPanel>
        </Box>
    );
}
