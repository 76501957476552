import React from 'react';
import DivLanguage from "../../components/DivLanguage";

const SectionWawDescription = (props) => {
    return (
        <section className="waw-description-section wave-section">

            <DivLanguage language="fr" className="center">
                <h2>
                    Notre histoire

                </h2>
                <p className="p20-light">
                    Fondée en 2015, Alphawave a investi dans le développement de l'application
                    Osiris afin de répondre à un besoin accru du secteur autour de la transformation
                    et l'organisation des données nécessaires à la finance et au suivi des risques.
                </p>
                <p className="p20-light">
                    Aujourd'hui, l'application Osiris permet à nos clients de toutes tailles
                    d'absorber de grandes quantités de données internes et/ou externes pour en exploiter
                    tout le potentiel dans leurs processus internes: comptabilité, gestion des risques,
                    tarification, ...
                </p>
                <p className="p20-light">
                    Nous mettons tout notre engagement dans l'accompagnement de nos clients afin que
                    l'intégration d'Osiris soit un succès.
                    Nous sommes à leur écoute et faisons évoluer Osiris en continu. Nous proposons chaque
                    semestre une nouvelle version de l'application basée sur l'expérience de nos clients
                    et les tendances technologiques.
                </p>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <h2>
                    Our story
                </h2>
                <p className="p20-light">
                    Founded in 2015, Alphawave has invested in developing the
                    Osiris application to meet the increasing needs of the sector
                    in terms of data transformation and organization, essential for
                    finance and risk monitoring.
                </p>
                <p className="p20-light">
                    Today, the Osiris application allows our clients of all sizes to absorb large
                    quantities of internal and/or external data to fully harness its
                    potential in their internal processes: accounting, risk management, pricing, etc.
                </p>
                <p className="p20-light">
                    We are fully committed to supporting our clients to ensure the successful
                    integration of Osiris. We are attentive to their needs and continuously improve Osiris.
                    Every semester, we release a new version of the application based on our
                    clients' experiences and technological trends.
                </p>
            </DivLanguage>
        </section>
    )
};

export default SectionWawDescription;