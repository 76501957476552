import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from "@mui/material";
import CustomTabPanel from "../../../../components/CustomTabPanel";

export default function OsirisTab2() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}
             className="tabs-container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}
                 className="tabs-group">
                <Tabs value={value} onChange={handleChange}
                      aria-label="basic tabs example">
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-diagram-project"></i>
                            <span>Versatile</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-diagram-next"></i>
                            <span>Dynamic</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-microchip"></i>
                            <span>Technological</span>
                        </div>
                    }/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <p className="p20-light">
                    The entire<strong> data management chain </strong>
                    can be integrated into the tool:
                    standardization, storage, analysis,
                    and data utilization.
                </p>

                <p className="p20-light">
                    The solution offers<strong> various
                    services: </strong>financial flow controls,
                    account automation, regulatory report feeding,
                    pricing, and risk monitoring.
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <p className="p20-light">
                    Users work in<strong> interaction </strong>with
                    the reader to qualify and
                    <strong> validate </strong>the data.
                </p>

                <p className="p20-light">
                    They also have<strong> control </strong>
                    over their files in the explorer and
                    can use or<strong> develop </strong>
                    their own reports in the library:
                    <strong> consistency checks </strong>
                    (head-to-head data and slips),
                    <strong> statistics </strong>(run-off triangles)
                    or<strong> regulatory data </strong>(ministerial statements).
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <p className="p20-light">
                    The<strong> SaaS deployment </strong>
                    on a private cloud makes management,
                    maintenance, and installation simple
                    and<strong> flexible. </strong>The SaaS mode
                    ensures the solution's scalability
                    and<strong> minimizes costs.</strong>
                </p>

                <p className="p20-light">
                    Furthermore, all flows and
                    data are<strong> secured </strong>and
                    the audit trail is fully preserved.
                </p>
            </CustomTabPanel>
        </Box>

    );
}
