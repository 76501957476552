import React from 'react';
import "../../style/who-are-we.scss";
import SectionContact from "../../components/SectionContact";
import SectionWawTop from "./SectionWawTop";
import SectionWawDescription from "./SectionWawDescription";
import SectionWawTeam from "./SectionWawTeam";
import SectionWawExpertise from "./SectionWawExpertise";
import VismeNewsLetterComponent from "../../components/visme/NewsletterSubscribe";

const WhoAreWePage = (props) => {
    return (
        <>
            <SectionWawTop/>
            <VismeNewsLetterComponent/>
            <SectionWawDescription/>

            <SectionWawTeam/>

            <SectionWawExpertise/>

            <SectionContact className="blue-wave"/>
        </>
    )
};

export default WhoAreWePage;