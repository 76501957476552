import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from "@mui/material";
import CustomTabPanel from "../../../../components/CustomTabPanel";

export default function OsirisTab1() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }}
             className="tabs-container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}
                 className="tabs-group">
                <Tabs value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example">
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-cloud-arrow-up"></i>
                            <span>Plateforme Web</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-database"></i>
                            <span>Data Management</span>
                        </div>
                    }/>
                    <Tab className="tab" label={
                        <div className="icon-n-text">
                            <i className="fa-solid fa-umbrella"></i>
                            <span>Assurance</span>
                        </div>
                    }/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <p className="p20-light">
                    La solution est déployée sur
                    un<strong> cloud </strong>répondant
                    aux besoins du client. Cet espace dédié
                    permet de connecter des nouveaux
                    services ou de gérer en temps réel
                    les ressources de calcul nécessaires.
                </p>

                <p className="p20-light">
                    L’application est<strong> accessible </strong>
                    simplement par la communauté d’utilisateurs
                    pour optimiser le<strong> partage </strong>de
                    l’information.
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <p className="p20-light">
                    Notre combinaison de solutions RPA
                    offre un outil à la fois capable
                    de<strong> gérer les données et
                    d’exploiter </strong>leur potentiel.
                </p>

                <p className="p20-light">
                    Ces solutions sont principalement
                    basées sur des techniques
                    de<strong> data science </strong>et
                    développées avec les langages R ou Python.
                </p>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <p className="p20-light">
                    La solution est spécifiquement conçue
                    pour répondre aux besoins
                    des<strong> assureurs </strong>et
                    des<strong> réassureurs</strong>.
                </p>

                <p className="p20-light">
                    Elle s’intégre complètement dans
                    les<strong> processus </strong>des métiers
                    du secteur et assure le niveau de
                    <strong> sécurité </strong>requis pour
                    protéger les données et les calculs.
                </p>
            </CustomTabPanel>
        </Box>
    );
}
