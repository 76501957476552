import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import PageError from "./pages/PageError";
import Contact from "./pages/Contact";
import OsirisPage from "./pages/Osiris/OsirisPage";
import ProductsPage from "./pages/Products/ProductsPage";
import WhoAreWE from "./pages/WhoAreWe/WhoAreWePage";
import Header from "./components/menu/Header";
import LegalInformations from "./pages/LegalInformations";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import CookieConsent from "./components/CookieConsent";
import {useCookies} from "react-cookie";


const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <PageError/>,
        children: [
            {
                path: '',
                element: <HomePage/>
            },
            {
                path: 'osiris',
                element: <OsirisPage/>
            },
            {
                path: 'products',
                element: <ProductsPage/>
            },
            {
                path: 'who-are-we',
                element: <WhoAreWE/>
            },
            {
                path: 'contact',
                element: <Contact/>
            },
            {
                path: 'legal-informations',
                element: <LegalInformations/>
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy/>
            },
        ]
    }
]);

function Root() {

    const [cookies] = useCookies(["cookieConsent"]);

    return <>

        <Header/>
        <main>
            <ScrollToTop/>

            <Outlet/>
        </main>
        {!cookies.cookieConsent && <CookieConsent/>}
        <Footer/>
    </>
}


const App = () => {


    return <RouterProvider router={router}/>
}

export default App