import React from 'react';
import {NavLink} from "react-router-dom";
import "../../style/components/card-link.scss"

const CardLink = ({title= "Titre par défaut", children, link, className, text="Texte par défaut"}) => {
    if (link)
        return (
            <NavLink to={link}
                     className={className + " card card-hover"}>
                <div className="top">
                    <h3>{title}</h3>
                    <>{children}</>
                </div>
                <div className="bottom">
                    <NavLink to={link}>Je découvre</NavLink>
                    <svg width="39" height="8" viewBox="0 0 39 8" fill="none">
                        <path className="arrow" d="M38.8536 4.35355C39.0488 4.15829 39.0488 3.84171 38.8536 3.64645L35.6716 0.464466C35.4763 0.269204 35.1597 0.269204 34.9645 0.464466C34.7692 0.659728 34.7692 0.976311 34.9645 1.17157L37.7929 4L34.9645 6.82843C34.7692 7.02369 34.7692 7.34027 34.9645 7.53553C35.1597 7.7308 35.4763 7.7308 35.6716 7.53553L38.8536 4.35355ZM0 4.5H38.5V3.5H0V4.5Z" fill="white"/>
                    </svg>
                </div>
            </NavLink>
        )
    return (
        <div className={className + " card"}>
            <div className="top">
                <h3>{title}</h3>
                <>{children}</>
            </div>
            <div className="bottom">
            </div>
        </div>
    )
};




export default CardLink;