import React from 'react';
import CardBigIcon from "../../components/card/CardBigIcon";
import DivLanguage from "../../components/DivLanguage";
import BtnDocumentDownload from "../../components/ButtonDocumentDownload";

const SectionOsirisSolution = (props) => {
    return (
        <section className="solution-section wave-section">
            <DivLanguage language="fr" className="center">
                <h2>
                    Une plateforme complète pour les métiers de l'actuariat et de la finance
                </h2>
                <div className="wrapper-quart">
                    <CardBigIcon title="Préparation des données"
                                 icon="fa-solid fa-database">
                        <p className="p18-regular">
                            Simplifier et automatiser la
                            transformation des données
                        </p>
                        <p className="p18-regular">
                            Réaliser des calculs avancés
                            sur vos données (R ou Python)
                        </p>
                        <p className="p18-regular">
                            Automatiser les contrôles de
                            qualité des données.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title="Analyse des données"
                                 icon="fa-solid fa-magnifying-glass-chart">
                        <p className="p18-regular">
                            Explorer vos données
                        </p>
                        <p className="p18-regular">
                            Exporter vos comptes de résultats
                        </p>
                        <p className="p18-regular">
                            Profitez de R et de Python pour modéliser
                            vos processus métiers mêmes complexes.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title="Workflow d’analyses"
                                 icon="fa-solid fa-sitemap">
                        <p className="p18-regular">
                            Organiser et planifier l’éxécution
                            de vos tâches de calculs.
                        </p>
                        <p className="p18-regular">
                            Suivez les dépendances entre vos résultats et gagnez en auditabilité.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title="Sécurité"
                                 icon="fa-solid fa-user-shield">
                        <p className="p18-regular">
                            Environnements dédiés et sécurisés.
                        </p>
                        <p className="p18-regular">
                            Accès par SSO et/ou MFA.
                        </p>
                        <p className="p18-regular">
                            Suivi des tentatives d'accès à l'application et aux données en temps réel.
                        </p>
                        <p className="p18-regular">
                            Protection des données par clé d'encryptage dédiée à l'environnement.
                        </p>
                    </CardBigIcon>
                </div>

                <div className="bottom">
                    <p className="p20-medium">
                        OSIRIS est une plateforme web de Data Management.
                        Elle permet aux utilisateurs de
                        stocker et d'organiser leurs données dans un datalake sous un format standardisé.
                    </p>
                    <p className="p20-medium">
                        Osiris peut lire des données brutes telles
                        que des données de gestion  d’assurance ou
                        des bordereaux. La lecture fonctionne aussi sur des
                        données  déjà transformées : comptes
                        d’assurance ou de réassurance, cash-flows
                        de modélisation, hypothèses de tarification, etc.
                    </p>
                    <p className="p20-medium">
                        L'ouverture aux API permet aussi à
                        la communauté d’utilisateurs d’exploiter
                        ses données pour les  différents besoins
                        de modélisation et d’analyse de l’entreprise.
                    </p>
                </div>
                <BtnDocumentDownload buttonLabel={"Documentation de l'API"} documentPath={"/APIs/apiguide-pdf.pdf"}/>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <h2>
                    A comprehensive platform for actuarial and finance professions
                </h2>
                <div className="wrapper-quart">
                    <CardBigIcon title="Data Preparation"
                                 icon="fa-solid fa-database">
                        <p className="p18-regular">
                            Simplify and automate
                            data transformation
                        </p>
                        <p className="p18-regular">
                            Perform advanced calculations
                            on your data (R or Python)
                        </p>
                        <p className="p18-regular">
                            Automate data quality
                            controls.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title="Data Analysis"
                                 icon="fa-solid fa-magnifying-glass-chart">
                        <p className="p18-regular">
                            Explore your data
                        </p>
                        <p className="p18-regular">
                            Export your income statements
                        </p>
                        <p className="p18-regular">
                            Use R and Python to model
                            even complex business processes.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title="Analysis Workflow"
                                 icon="fa-solid fa-sitemap">
                        <p className="p18-regular">
                            Organize and schedule the execution
                            of your calculation tasks.
                        </p>
                        <p className="p18-regular">
                            Track dependencies between your results and enhance auditability.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title="Security"
                                 icon="fa-solid fa-user-shield">
                        <p className="p18-regular">
                            Dedicated and secure environments.
                        </p>
                        <p className="p18-regular">
                            Access via SSO and/or MFA.
                        </p>
                        <p className="p18-regular">
                            Monitor access attempts to the application and data in real time.
                        </p>
                        <p className="p18-regular">
                            Data protection with an encryption key dedicated to the environment.
                        </p>
                    </CardBigIcon>
                </div>

                <div className="bottom">
                    <p className="p20-medium">
                        OSIRIS is a web-based Data Management platform.
                        It allows users to
                        store and organize their data in a datalake in a standardized format.
                    </p>
                    <p className="p20-medium">
                        Osiris can read raw data such
                        as insurance management data or
                        slips. Reading also works on
                        already transformed data: insurance
                        or reinsurance accounts, modeling cash flows, pricing assumptions, etc.
                    </p>
                    <p className="p20-medium">
                        The openness to APIs also allows
                        the user community to leverage
                        their data for various modeling and analysis needs of the company.
                    </p>
                </div>
                <BtnDocumentDownload buttonLabel={"API Documentation"} documentPath={"/APIs/apiguide-pdf.pdf"}/>
            </DivLanguage>

        </section>
    )
};

export default SectionOsirisSolution;