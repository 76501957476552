import React from 'react';
import CardTeamProfile from "../../components/card/CardTeamProfile";
import ImageSP from "../../assets/images/img-seb.jpg";
import ImageTB from "../../assets/images/img-thom.jpg";
import DivLanguage from "../../components/DivLanguage";

const SectionWawTeam = (props) => {
    return (
        <section className="waw-team wave-section">
            <div className="center">

                <DivLanguage language="fr" >
                    <h2>Les associés</h2>
                </DivLanguage>
                <DivLanguage language="en" >
                    <h2>The partners</h2>
                </DivLanguage>

                <DivLanguage language="fr" className="wrapper-demi">
                    <CardTeamProfile name="Sébastien Potherat"
                                     jobTitle="Fondateur"
                                     link="https://www.linkedin.com/in/s%C3%A9bastien-potherat-460824a9/"
                                     image={ImageSP}>
                        <p className="p18-regular">
                            « Financier et statisticien, j’ai travaillé
                            douze ans au sein de directions financières
                            de deux acteurs majeurs de l’assurance de
                            personnes. Au cours de ces deux expériences,
                            j’ai participé à des projets stratégiques
                            de modélisation financière, d’ALM et de
                            gestion des risques (Solvabilité 2).
                        </p>
                        <p className="p18-regular">
                            Les opportunités offertes par les avancées
                            technologiques et mon appétence pour les
                            techniques quantitatives m’ont convaincu
                            qu’il était possible d’apporter des solutions
                            de modélisation et d’automatisation différentes.
                        </p>
                        <p className="p18-regular">
                            Poussé par l’envie d’entreprendre, j’ai fondé
                            Alphawave en 2015 après plus d’un an de
                            développement sur une version beta d’Osiris
                            en client lourd. Dès 2016, nous avons sorti
                            la version SaaS d’Osiris Enterprise, que nous
                            avons continué à faire évoluer en parallèle
                            des missions de conseil. Ces travaux ont permis
                            d’aboutir à un déploiement chez notre premier
                            client en 2017. »
                        </p>
                    </CardTeamProfile>

                    <CardTeamProfile name="Thomas Boudinaud"
                                     jobTitle="Associé"
                                     link="https://www.linkedin.com/in/thomas%2Dboudinaud%2Daa889933/"
                                     image={ImageTB}>
                        <p className="p18-regular">
                            « Actuaire de formation, j’ai travaillé
                            neuf ans dans des directions financières
                            de l’industrie de l’assurance.
                        </p>
                        <p className="p18-regular">
                            Spontanément intéressé par les sujets de
                            modélisation, mes expériences professionnelles
                            m’ont orienté sur des projets transverses autour
                            des données : d’abord chez l’un des acteurs
                            majeurs de l’assurance de personnes en France,
                            puis dans la succursale française de l’un des
                            leaders internationaux de la réassurance.
                        </p>
                        <p className="p18-regular">
                            Chez ce dernier, en tant que responsable de
                            l’équipe Valorisation et Modélisation, j’ai
                            notamment dirigé les projets d’automatisation
                            des processus de clôture, d’alimentation des
                            modèles de projection de passif et d’intégration
                            des données.
                        </p>
                        <p className="p18-regular">
                            Depuis Octobre 2019, je me suis engagé dans
                            l’aventure Alphawave pour apporter des solutions
                            adaptées sur ces sujets aux entreprises du secteur.
                        </p>
                    </CardTeamProfile>

                </DivLanguage>
                <DivLanguage language="en" className="wrapper-demi">
                    <CardTeamProfile name="Sébastien Potherat"
                                     jobTitle="Founder"
                                     link="https://www.linkedin.com/in/s%C3%A9bastien-potherat-460824a9/"
                                     image={ImageSP}>
                        <p className="p18-regular">
                            "As a financier and statistician, I worked for twelve years in
                            financial management at two major personal insurance companies. During these experiences, I
                            was involved in strategic projects related to financial modeling, Asset Liability Management
                            (ALM), and risk management (Solvency II).
                        </p>
                        <p className="p18-regular">
                            The opportunities
                            provided by technological advances and my keen interest in quantitative techniques convinced
                            me
                            that it was possible to offer different modeling and automation solutions.
                        </p>
                        <p className="p18-regular">
                            Driven by the desire to start my own business, I founded Alphawave in
                            2015 after more than a year of developing a beta version of Osiris as a client-based
                            application. In 2016, we released the SaaS version of Osiris Enterprise, which we continued
                            to
                            develop alongside consulting projects. This work led to the deployment with our first client
                            in
                            2017."
                        </p>
                    </CardTeamProfile>

                    <CardTeamProfile name="Thomas Boudinaud"
                                     jobTitle="Partner"
                                     link="https://www.linkedin.com/in/thomas%2Dboudinaud%2Daa889933/"
                                     image={ImageTB}>
                        <p className="p18-regular">
                            "Trained as an actuary, I spent nine years in financial management
                            within the insurance industry.
                        </p>
                        <p className="p18-regular">
                            Having a natural interest in
                            modeling, my professional experiences have led me to work on cross-functional projects
                            related
                            to data: initially, at one of the major personal insurance companies in France, and then at
                            the
                            French branch of one of the leading international reinsurance companies.
                        </p>
                        <p className="p18-regular">
                            At the latter, as head of the Valuation and Modeling team, I directed
                            projects focused on the automation of closing processes, feeding liability projection
                            models,
                            and data integration.
                        </p>
                        <p className="p18-regular">
                            Since October 2019, I have been engaged
                            in the Alphawave venture to provide tailored solutions in these areas to businesses in the
                            sector."
                        </p>
                    </CardTeamProfile>

                </DivLanguage>

            </div>
        </section>
    )
};

export default SectionWawTeam;